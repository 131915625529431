import React, { useState } from "react"
import Layout from "../components/layout"
import logo from "../images/Lancelot PianoB.png"
import styled from "styled-components"
import back from "../images/ContactBack-min.jpg"
import SEO from "../components/seo"

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    mail: "",
    phone: "",
    message: "",
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSudmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error))

    e.preventDefault()
  }
  return (
    <Layout logo={logo} color="#333" menu="Accueil" lien="/">
      <SEO title="Cours de Piano. Cours particuliers de piano à domicile sur Toulon et ses environs. Tout niveau, enfants-ados-adultes | Contact" />
      <BackgroundWrapper />
      <ContactWrapper>
        <h2>Une question sans réponse ? Contactez-nous !</h2>
        <ContactCard>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSudmit}
            action="/Success"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label className="label">Votre nom :</label>{" "}
            </p>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={formState.name}
            />
            <p>
              <label>Votre Email :</label>
            </p>
            <input
              type="email"
              name="mail"
              onChange={handleChange}
              value={formState.mail}
            />
            <p>
              <label>Téléphone:</label>{" "}
            </p>
            <input
              name="phone"
              onChange={handleChange}
              value={formState.phone}
            ></input>
            <p>
              <label>Message:</label>{" "}
            </p>{" "}
            <textarea
              name="message"
              rows="5"
              onChange={handleChange}
              value={formState.message}
            ></textarea>
            <button className="btn" type="submit">
              Envoyer
            </button>
          </form>
        </ContactCard>
        <h3>Ou par téléphone au 06.41.35.93.22</h3>
      </ContactWrapper>
    </Layout>
  )
}

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 6rem;
  left: 0;
  background: url(${back});
  background-size: cover;
  filter: brightness(0.9);
  height: 100vh;
  width: 100%;
  z-index: 1;
`

const ContactWrapper = styled.div`
  min-height: 80vh;
  height: auto;
  width: 80%;
  margin: 0 auto;
  padding: 3rem;
  text-align: center;
  box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.02),
    0 2.2px 5.3px rgba(0, 0, 0, 0.028), 0 4.1px 10px rgba(0, 0, 0, 0.035),
    0 7.4px 17.9px rgba(0, 0, 0, 0.042), 0 13.8px 33.4px rgba(0, 0, 0, 0.05),
    0 33px 80px rgba(0, 0, 0, 0.07);
  z-index: 1000;
  position: relative;
  background: #fff;

  h2 {
    font-family: "Roboto Condensed", sans-serif;
  }

  h3 {
    margin: 5.5rem 0 0 0;
    font-family: "Roboto Condensed", sans-serif;
  }
`
const ContactCard = styled.div`
  width: 80%;
  margin: 0 auto;
  height: auto;
  position: relative;
  /* padding: 1rem; */

  p {
    margin-top: 1rem;
    margin-bottom: 0;
    color: #333;
    text-align: left !important;
  }

  input {
    height: 2rem;
    border: 0.1rem solid #333;
    box-sizing: border-box;
    -moz-appearance: textfield;
    outline: none;
    width: 100%;
    margin-top: -1rem;
  }

  textarea {
    min-height: 7rem;
    max-height: 7rem;
    min-width: 100%;
    max-width: 100%;
    border: 0.1rem solid #333;
    outline: none;
  }

  button {
    width: 7rem;
    height: 3rem;
    margin: 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #333;
    color: #333;
    background: #fff;
    transition: 0.5s ease-in-out;
    outline: none;
    position: absolute;
    top: 20rem;
    left: 0;
  }
  button:hover {
    border: 0.1rem solid #fff;
    color: #fff;
    background: rgba(56, 182, 255, 1);
  }
  .hidden {
    display: none;
  }
`

export default Contact
